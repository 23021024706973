.merch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
}
.merch > img {
  margin-bottom: -150px;
}
.linkToStore {
  display: none;
}
.homeBTN {
  width: 200px;
}
@media screen and (min-width: 1025px) {
  .merch {
    /* background-color: rgb(245, 212, 155); */
    padding-top: 180px;
    padding-bottom: 180px;
    margin-top: -530px;
    height: 1400px;
  }
  .merch > img {
    width: 605px;
  }
  .linkToStore {
    display: block;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1600px) {
  .merch {
    /* background-color: rgb(245, 212, 155); */
    padding-top: 180px;
    padding-bottom: 180px;
    margin-top: -480px;
    height: 1400px;
  }
}

@media screen and (max-height: 600px) {
  .merch {
    height: 900px;
    margin-top: 50px;
  }
  .merch > img {
    margin-bottom: 0;
  }
}
