.traits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

.linkToStore {
  display: none;
  margin-top: -40px;
}
.trait2 {
  margin-bottom: -270px;
}
.homeBTN {
  width: 200px;
}
@media screen and (min-width: 1025px) {
  .traits {
    padding-top: 180px;
    padding-bottom: 180px;
    margin-top: -150px;
    height: 1300px;
  }
  .traits > img {
    width: 605px;
  }
  .linkToStore {
    display: block;
    margin-bottom: 10px;
  }
}
