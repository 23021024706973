a:link {
  text-decoration: none !important;
  color: white;
}
a,
a:visited,
a:hover,
a:active {
  color: inherit;
}
.Team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 140px;
  margin-bottom: 50px;
  height: 4200px;
}

.linkToStore {
  display: none;
}

.team-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -200px;
}
.team-context {
  text-align: center;
  font-size: 14px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: -40px;
}
.teamMembers {
  color: #008000;
}
.homeBTN {
  width: 200px;
}
.teamMembers-image {
  border-radius: 50px;
}
.team-context > p {
  margin-top: -10px;
}
.feline-context {
  margin-top: 10px;
}

.team-context > h2 {
  margin-top: 70px;
}

.twitterHandle {
  font-size: 16px;
  margin-top: -5px;
  cursor: url("./images/cursorHandIn.png"), auto;
  color: black;
}
@media screen and (min-width: 1025px) {
  .Team {
    /* background-color: rgb(245, 212, 155); */
    padding-top: 245px;
    padding-bottom: 180px;
    margin-top: -700px;
    height: 7000px;
    margin-bottom: 0px;
  }
  .linkToStore {
    display: block;
    margin-bottom: 10px;
  }
  .teamMembers-image {
    border-radius: 50px;
    width: 500px;
  }
}

@media screen and (min-width: 1600px) {
  .Team {
    /* background-color: rgb(245, 212, 155); */
    padding-top: 180px;
    padding-bottom: 180px;
    margin-top: -630px;
    height: 8000px;
    margin-bottom: 0px;
  }
  .team-context {
    text-align: center;
    font-size: 24px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .Team-container > h1 {
    font-size: 42px;
  }
  .feline-context {
    margin-top: 10px;
    font-size: 24px;
  }
}

@media screen and (max-height: 900px) {
  .Team > img {
    margin-bottom: 0;
  }
}
