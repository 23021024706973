.roadmap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
  margin-bottom: 50px;
}

.linkToStore {
  display: none;
}
.homeBTN {
  width: 200px;
}
.ContentDown {
  margin-top: -80px;
  margin-bottom: -150px;
  cursor: url("./images/cursorHandIn.png"), auto;
}
.ContentUp {
  margin-top: -50px;
  margin-bottom: -140px;
  cursor: url("./images/cursorHandIn.png"), auto;
}
@media screen and (min-width: 1025px) {
  .roadmap {
    /* background-color: rgb(245, 212, 155); */
    padding-top: 180px;
    padding-bottom: 180px;
    margin-top: -100px;
    margin-bottom: 0px;
  }
  .roadmap > img {
    width: 605px;
  }
  .linkToStore {
    display: block;
    margin-bottom: 10px;
  }
  .ContentDown {
    margin-top: -600px;
    margin-bottom: 0px;
  }
  .ContentUp {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1600px) {
  .roadmap {
    /* background-color: rgb(245, 212, 155); */
    padding-top: 180px;
    padding-bottom: 180px;
    margin-top: -325px;
    height: 1400px;
    margin-bottom: 0px;
  }
}
