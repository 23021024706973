.Bodega {
    background-color: black;
  }
  .Mobiile-V {
    display: none;
  }
  .header {
    height: 80px;
    background-color: black;
    display: flex;
  
    align-items: center;
  }
  .header-left {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 50px;
  }
  .header-left > a {
    cursor: url("./images/cursorHandIn.png"), auto;
  }
  
  .BodegaLOGO {
    width: 250px;
    margin-left: 50px;
  }
  .Season2 {
    width: 132px;
  }
  .header-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 60px;
  }
  .header-right > a {
    cursor: url("./images/cursorHandIn.png"), auto;
  }
  .bodega-h1 {
    font-weight: 900;
    color: yellow;
  }
  .bodega-h1 > a {
    cursor: url("./images/cursorHandIn.png"), auto;
  }
  
  .header-clothing {
    display: flex;
  }
  .bodega-clothing-h1 {
    color: gray;
  }
  .BLOCK {
    width: 120px;
    margin-left: -110px;
  }
  .BodegaTwitter {
    width: 70px;
    height: 60px;
    margin-top: 10px;
  }
  .BodegaDiscord {
    width: 70px;
    height: 60px;
    margin-right: 50px;
    margin-top: 10px;
  }
  .Bodega-Container {
    display: flex;
    background: url("./images/Bodega/backgroundSVG.svg");
    /* background-position: 160px 0px; */
    height: 1100px;
    background-repeat: no-repeat;
    background-position: cover;
    background-size: auto;
  
    position: relative;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .middle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
    position: absolute;
  
    bottom: 10px;
    left: 160px;
    right: 160px;
  }
  .shelf {
    width: 1000px;
  }
  .middle > svg {
    width: 100%;
    height: 42%;
  }
  
  .left-col {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
  
  .right-col {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  .Bodega-Footer {
    margin-top: -40px;
    height: 500px;
    background-image: url("./images/Bodega/Footerbacground.svg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Bodega-Footer-Container {
    width: 975px;
    height: 294px;
    background-color: black;
  }
  
  .bodega-logo-footer {
    width: 326px;
  }
  .box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 60px;
    margin-top: 30px;
  }
  .footer-1 {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .footer-1 > p {
    margin-left: -60px;
  }
  
  .footer-2,
  .footer-3,
  .footer-4 {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
  }
  .footer-2 > h2 {
    font-weight: 900;
  }
  .footer-3 > h2 {
    font-weight: 900;
  }
  .footer-4 > h2 {
    font-weight: 900;
  }
  
  .footer-2 > p {
    font-size: 16px;
    margin-top: -2px;
  }
  .footer-3 > p {
    font-size: 16px;
    margin-top: -2px;
  }
  .footer-4 > p {
    font-size: 16px;
    margin-top: -2px;
  }
  .footer-4 {
    font-size: 16px;
    column-gap: 10px;
    margin-top: -2px;
  }
  
  p > a {
    cursor: url("./images/cursorHandIn.png"), auto;
  }
  
  .meetTheTeamIMG {
    width: 100px;
    position: absolute;
    z-index: 10;
    left: 165px;
    top: 400px;
    cursor: url("./images/cursorHandIn.png"), auto;
  }
  
  .meowTraitsIMG {
    width: 160px;
    position: absolute;
    z-index: 10;
    left: 405px;
    top: 450px;
    cursor: url("./images/cursorHandIn.png"), auto;
  }
  
  .roadmapIMG {
    width: 280px;
    position: absolute;
    z-index: 10;
    left: 640px;
    top: 450px;
    cursor: url("./images/cursorHandIn.png"), auto;
  }
  
  .nipIMG {
    width: 150px;
    position: absolute;
    z-index: 10;
    top: 710px;
    left: 415px;
  }
  
  .nipIMG:hover {
    -webkit-animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  
  .roadmapIMG:hover {
    -webkit-animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .meowTraitsIMG:hover {
    -webkit-animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  
  .meetTheTeamIMG:hover {
    -webkit-animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.25);
      transform: scale(1.25);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.25);
      transform: scale(1.25);
    }
  }
  
  @media only screen and (max-width: 1071px) {
    .header {
      display: none;
    }
    .Bodega-Container {
      display: none;
    }
    .Bodega-Footer {
      display: none;
    }
    .meetTheTeam {
      display: none;
    }
    .meowTraits {
      display: none;
    }
    .roadMap {
      display: none;
    }
    .Mobiile-V {
      height: 500px;
      display: block;
      position: absolute;
      left: 50px;
      right: 50px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 100px;
      text-align: center;
      color: black;
    }
  }
  
  @media only screen and (min-width: 2000px) {
    .Bodega-Container {
      display: flex;
      background: url("./images/Bodega/backgroundSVG.svg");
      /* background-position: 160px 0px; */
      height: 1100px;
      background-repeat: no-repeat;
      /* background-position: cover; */
      background-size: 2440px 1340px;
  
      position: relative;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
    .Bodega-Footer-Container {
      width: 975px;
      height: 340px;
      background-color: black;
    }
    .meetTheTeamIMG {
      width: 140px;
      position: absolute;
      z-index: 10;
      left: 350px;
      top: 425px;
    }
  
    .meowTraitsIMG {
      width: 260px;
      position: absolute;
      z-index: 10;
      left: 900px;
      top: 350px;
    }
  
    .roadmapIMG {
      width: 380px;
      position: absolute;
      z-index: 10;
      left: 1400px;
      top: 350px;
    }
    .nipIMG {
      width: 150px;
      position: absolute;
      z-index: 10;
      top: 630px;
      left: 705px;
    }
  
    .footer-2 > p {
      font-size: 20px;
      margin-top: -2px;
    }
    .footer-3 > p {
      font-size: 20px;
      margin-top: -2px;
    }
    .footer-4 > p {
      font-size: 20px;
      margin-top: -2px;
    }
    .footer-4 {
      font-size: 20px;
      column-gap: 10px;
      margin-top: -2px;
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1599px) {
    .meetTheTeamIMG {
      width: 135px;
      position: absolute;
      z-index: 10;
      left: 262px;
      top: 360px;
    }
  
    .meowTraitsIMG {
      width: 160px;
      position: absolute;
      z-index: 10;
      left: 640px;
      top: 350px;
    }
  
    .roadmapIMG {
      width: 290px;
      position: absolute;
      z-index: 10;
      left: 905px;
      top: 520px;
    }
  }
  
  @media (min-width: 1600px) and (max-width: 1999px) {
    .meetTheTeamIMG {
      width: 150px;
      position: absolute;
      z-index: 10;
      left: 357px;
      top: 286px;
    }
  
    .meowTraitsIMG {
      width: 210px;
      position: absolute;
      z-index: 10;
      left: 770px;
      top: 330px;
    }
  
    .roadmapIMG {
      width: 300px;
      position: absolute;
      z-index: 10;
      left: 1123px;
      top: 500px;
    }
  }
  
  @media only screen and (min-width: 2400px) {
    .Bodega-Container {
      display: flex;
      background: url("./images/Bodega/backgroundSVG.svg");
      /* background-position: 160px 0px; */
      height: 1100px;
      background-repeat: no-repeat;
      /* background-position: cover; */
      background-size: 2440px 1340px;
  
      position: relative;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
    .Bodega-Footer-Container {
      width: 975px;
      height: 340px;
      background-color: black;
    }
    .meetTheTeamIMG {
      width: 160px;
      position: absolute;
      z-index: 10;
      left: 350px;
      top: 425px;
    }
  
    .meowTraitsIMG {
      width: 260px;
      position: absolute;
      z-index: 10;
      left: 1100px;
      top: 350px;
    }
  
    .roadmapIMG {
      width: 380px;
      position: absolute;
      z-index: 10;
      left: 1700px;
      top: 350px;
    }
    .nipIMG {
      width: 150px;
      position: absolute;
      z-index: 10;
      top: 500px;
      left: 805px;
    }
  
    .footer-2 > p {
      font-size: 20px;
      margin-top: -2px;
    }
    .footer-3 > p {
      font-size: 20px;
      margin-top: -2px;
    }
    .footer-4 > p {
      font-size: 20px;
      margin-top: -2px;
    }
    .footer-4 {
      font-size: 20px;
      column-gap: 10px;
      margin-top: -2px;
    }
  }
  