.legal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 250px;
}

.legal > img {
    margin-bottom: -150px;
}
.linkToStore {
    display: none;
}
.homeBTN {
    width: 200px;
}
  
.legal-links {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    font-size: 52px;
    color: black;
}
.legal-links > a:link {
    text-decoration: none !important;
    color: black;
    font-size: 30px;
}
  
.legal-links > a,
.legal-links > a:visited,
.legal-links > a:hover,
.legal-links > a:active {
    color: black;
}
  
@media screen and (min-width: 1025px) {
    .legal {
      /* background-color: rgb(245, 212, 155); */
      padding-top: 180px;
      padding-bottom: 180px;
      margin-top: -530px;
      height: 1400px;
    }
    .legal > img {
      width: 605px;
    }
    .linkToStore {
      display: block;
      margin-bottom: 10px;
    }
}
  
@media screen and (min-width: 1600px) {
    .legal {
      /* background-color: rgb(245, 212, 155); */
      padding-top: 180px;
      padding-bottom: 180px;
      margin-top: -320px;
      height: 1400px;
    }
}
  
@media screen and (max-height: 600px) {
    .legal {
      height: 900px;
      margin-top: 50px;
    }
    .legal > img {
      margin-bottom: 0;
    }
}
  