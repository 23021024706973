.Nip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 25px;
}

.linkToStore {
  display: none;
}

.nip-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -250px;
}
.nip-context {
  text-align: center;
  font-size: 14px;
  margin-left: 50px;
  margin-right: 50px;
}
.tokenNIP {
  color: #008000;
}
.homeBTN {
  width: 200px;
}

@media screen and (min-width: 1025px) {
  .Nip {
    /* background-color: rgb(245, 212, 155); */
    padding-top: 180px;
    padding-bottom: 180px;
    margin-top: -340px;
    height: 570px;
    margin-bottom: 0px;
  }
  .linkToStore {
    display: block;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1600px) {
  .Nip {
    /* background-color: rgb(245, 212, 155); */
    padding-top: 180px;
    padding-bottom: 180px;
    margin-top: -240px;
    height: 1400px;
    margin-bottom: 0px;
  }
  .nip-context {
    text-align: center;
    font-size: 24px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .nip-container > h1 {
    font-size: 42px;
  }
}

@media screen and (max-height: 900px) {
  .Nip {
    height: 1055px;
  }
  .Nip > img {
    margin-bottom: 0;
  }
}
