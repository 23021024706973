.App {
  overflow: hidden;
  max-width: 1920px;
  max-height: 1080px;
  height: 900px;
  width: auto;
  left: 0;
  right: 0;

  text-align: center;
  margin-top: -100px;
}
img {
  width: 750px;
}
.level-0 {
  margin-bottom: -518px;
}

.level-1 {
  margin-bottom: -790px;
}

.level-2-l {
  margin-bottom: -770px;
}

.level-2-r {
  margin-bottom: -753px;
}

.level-3-l {
  width: 50px;
}
.level-3-r {
  width: 50px;
}
.level-3 {
  margin-top: -260px;

  display: flex;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  column-gap: 125px;
  margin-bottom: 35px;
}
.door {
  margin-top: -235px;
}
.doorGIF {
  position: relative;
  width: 116px;
  z-index: 300;
  margin-top: -138px;
  margin-left: 11px;
}
/* .door:hover {
  -webkit-animation: swing-left-fwd 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: swing-left-fwd 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
} */
.bodega-sticker {
  margin-top: -602px;
}
.level-4 > img {
  width: 80px;
  margin-left: 10px;
}
.level-4 {
  margin-top: -495px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 100px;
}

.lightsGIF {
  width: 600px;
  margin-top: 300px;
  margin-bottom: -327px;
}

.level-5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  column-gap: 50px;
}

.NipMachineSticker {
  width: 50px;
  margin-left: -10px;
  margin-right: 20px;
}
.meowSticker {
  width: 80px;
  margin-top: 20px;
}

.OpenseaSticker {
  width: 50px;
}
.RocketSticker {
  width: 50px;
  margin-left: 70px;
}

.level-6 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -140px;
  column-gap: 60px;
}
.FreenipSticker {
  width: 80px;
  margin-right: -30px;
  margin-bottom: -60px;
}
.discordSticker {
  width: 50px;
  margin-right: 88px;
  margin-top: -20px;
}
.delistSticker {
  width: 50px;
  margin-left: -10px;
}
.Nip1sticker {
  width: 50px;
  margin-left: 10px;
}

.nextPageBTN {
  position: relative;
  text-align: center;
  z-index: 10000;
  margin-top: -450px;
}
.getIn {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100px;
  opacity: 0;
}
.getIn:hover {
  cursor: pointer;
  cursor: url("./images/cursorHandIn.png"), auto;
}

.footer {
  display: none;
}

.mobile-socials,
.TapMe2,
.TapMe,
.ipad {
  display: none;
}

@-webkit-keyframes swing-left-fwd {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
@keyframes swing-left-fwd {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  100% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}

.shake-left {
  -webkit-animation: shake-left 2.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 6s
    infinite both;
  animation: shake-left 2.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 6s infinite
    both;
}

@-webkit-keyframes shake-left {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes shake-left {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

.wobble-ver-left {
  -webkit-animation: wobble-ver-left 2.5s ease 6s infinite both;
  animation: wobble-ver-left 2.5s ease 6s infinite both;
}

@-webkit-keyframes wobble-ver-left {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(-6deg);
    transform: translateY(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(6deg);
    transform: translateY(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(-3.6deg);
    transform: translateY(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(2.4deg);
    transform: translateY(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(-1.2deg);
    transform: translateY(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-ver-left {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(-6deg);
    transform: translateY(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(6deg);
    transform: translateY(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(-3.6deg);
    transform: translateY(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(2.4deg);
    transform: translateY(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(-1.2deg);
    transform: translateY(-6px) rotate(-1.2deg);
  }
}

.jello-vertical {
  -webkit-animation: jello-vertical 2.5s 6s infinite both;
  animation: jello-vertical 2.5s 6s infinite both;
}

@-webkit-keyframes jello-vertical {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-vertical {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.vibrate-1 {
  -webkit-animation: vibrate-1 0.5s linear infinite both;
  animation: vibrate-1 0.5s linear infinite both;
}

@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
.pulsate-fwd {
  -webkit-animation: pulsate-fwd 0.8s ease-in-out infinite both;
  animation: pulsate-fwd 0.8s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.bounce-in-top-s {
  -webkit-animation: bounce-in-top 2s 0.5s both;
  animation: bounce-in-top 2s 0.5s both;
}
.bounce-in-top-m {
  -webkit-animation: bounce-in-top 2s 1s both;
  animation: bounce-in-top 2s 1s both;
}
.bounce-in-top-l {
  -webkit-animation: bounce-in-top 2s1.5s both;
  animation: bounce-in-top 2s 1.5s both;
}
.bounce-in-top-xl {
  -webkit-animation: bounce-in-top 2s 2s both;
  animation: bounce-in-top 2s 2s both;
}
.bounce-in-top-xxl {
  -webkit-animation: bounce-in-top 2s 2.5s both;
  animation: bounce-in-top 2s 2.5s both;
}
.bounce-in-top-xxxl {
  -webkit-animation: bounce-in-top 2s 3s both;
  animation: bounce-in-top 2s 3s both;
}

@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@media screen and (min-width: 1349px) {
  .App {
    width: 1499px;
  }
  .mainPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lightsGIF {
    margin-left: 80px;
    margin-right: 82px;
  }
}

@media screen and (max-width: 1024px) {
  .App {
    height: 850px;
    margin-bottom: -450px;
    margin-top: 0px;
  }
  img {
    width: 250px;
  }
  .lightsGIF {
    width: 200px;
  }
  .level-0 {
    margin-bottom: -407px;
  }
  .level-1 {
    margin-bottom: -463px;
  }

  .level-2-l {
    margin-bottom: -442px;
  }

  .level-2-r {
    margin-bottom: -424px;
  }

  .level-3-l {
    width: 15px;
  }
  .level-3-r {
    width: 15px;
  }
  .level-3 {
    margin-top: -94px;
    column-gap: 43px;
    margin-bottom: 35px;
  }
  .door {
    margin-top: 154px;
    width: 245px;
  }

  .level-4 {
    margin-top: -166px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 70px;
  }
  .bodega-sticker {
    margin-top: -215px;
  }
  .level-4 > img {
    width: 20px;
    margin-left: 10px;
  }
  .level-5 {
    width: 160px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    align-items: center;
    margin-top: 45px;
    column-gap: 0px;
  }

  .NipMachineSticker {
    width: 10px;
    margin: 0;
    margin-right: 10px;
  }

  .meowSticker {
    width: 25px;
    margin: 0;
    margin-left: 3px;
    margin-right: 8px;
  }

  .OpenseaSticker {
    width: 20px;
    margin: 0;
    margin-left: 10px;
  }
  .RocketSticker {
    width: 20px;
    margin: 0;
    margin-left: 50px;
  }

  .level-6 {
    width: 160px;
    margin: auto;
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;
    margin-top: -50px;
    column-gap: 10px;
  }
  .FreenipSticker {
    margin: 0;
    width: 20px;
    margin-left: 3px;
    margin-right: 8px;
    margin-bottom: -10px;
  }
  .discordSticker {
    margin: 0;

    width: 15px;
  }
  .delistSticker {
    margin: 0;
    margin-left: 10px;
    width: 10px;
  }
  .Nip1sticker {
    margin: 0;

    width: 20px;
    margin-left: 40px;
  }
  .wobble-ver-left {
    -webkit-animation: wobble-ver-left 2.5s ease 6s 1 both;
    animation: wobble-ver-left 2.5s ease 6s 1 both;
  }
  .bounce-in-top-ss {
    -webkit-animation: bounce-in-top 1s both;
    animation: bounce-in-top 1s both;
  }

  .bounce-out-top-ss {
    -webkit-animation: bounce-in-top 1.1s reverse both;
    animation: bounce-in-top 1.1s reverse both;
  }

  .footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 300px;
  }
  .Footer1 {
    width: 120px;
  }
  .Footer2 {
    width: 160px;
    margin-top: 10px;
  }
  .getIn {
    display: none;
  }
  .TapMe {
    display: block;
    width: 30px;
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .mobile-socials {
    display: block;
    position: absolute;

    top: 0;
    left: 30px;
    right: 30px;
    z-index: 199;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
  }
  .mobile-socials-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .FelineFiendzIcon {
    width: 35px;
  }
  .OpenseaM {
    width: 30px;
  }
  .Discord {
    width: 30px;
  }
  .TwitterM {
    width: 30px;
  }

  .TapMe2 {
    display: block;
    width: 30px;
    position: absolute;
    z-index: 200;
    top: 535px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .NavBar {
    position: absolute;
    z-index: 200;
    top: 0;
    background-color: rgb(225, 180, 117);
    left: 0;
    right: 0;
    background-image: url("./images/page1/Pull\ Down.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .NavBar-container {
    height: 445px;
    left: 0;
    right: 0;
    top: 0;
    color: white;
  }
  .NavBar-container > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    list-style-type: none;
    margin-top: 90px;
    margin-right: 40px;
    color: white;
  }
  .home-btn-navbar {
    position: absolute;
    z-index: 200;
    width: 100px;
    right: 10px;
    top: 10px;
  }
  .NavBar-container > ul > li {
    text-decoration: none;
    color: white;
    filter: drop-shadow(0px 5px 0px #392000);
  }
  a:link {
    text-decoration: none !important;
    color: white;
    font-size: 30px;
  }
  a,
  a:visited,
  a:hover,
  a:active {
    color: white;
  }
  .nextPageBTN {
    display: none;
  }
  #legalLi {
    filter: none;
  }
  #Legal-Navbar {
    margin-top: -4px;
    font-size: 22px;
    filter: none;
    text-shadow: 0px 3px 0px #392000;
  }
}

@media (min-width: 450px) and (max-width: 1023px) {
  .App {
    display: none;
  }
  .ipad {
    height: 500px;
    display: block;
    position: absolute;
    left: 50px;
    right: 50px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 100px;
    text-align: center;
  }
}
